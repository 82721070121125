/* General Styles */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html, button, input, select, textarea {
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    color: #666;
}
button, input, select, textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}
textarea, input {
    outline: none;
}
textarea {
    overflow: auto;
    vertical-align: top;
}

/* Layout Stuff */
.comment-section {
    width: 100%;
    max-width: 760px;
    margin: 30px auto;
}
h1 {
    padding: 15px;
    margin: 0;
}
#commentform {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
}
#commentform > [class^="comment-form"],
#commentform > .form-submit {
    position: relative;
    min-height: 1px;
    padding: 15px;
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 600px) {
    #commentform > .comment-form-name,
    #commentform > .comment-form-surname,
    #commentform > .comment-form-email,
    #commentform > .comment-form-url {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
                -ms-flex: 0 0 50%;
                        flex: 0 0 50%;
        max-width: 50%;
    }
}


/* Form Stuff */
#commentform input[type="text"], #commentform input[type="email"], #commentform input[type="url"], #commentform textarea {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    padding: 24px 10px 12px;
    font-size: 16px;
    font-size: 1rem;
}

input[type="text"], input[type="email"], input[type="url"], textarea {
    background-color: #fff;
    padding: 24px 10px 12px;
    font-size: 16px;
    font-size: 1rem;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
:focus {
    outline: 0;
}
input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, textarea:focus {
    background-color: #fff;
}


/*Animations*/
@-webkit-keyframes silo-fade {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-1.4em);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}
@-moz-keyframes silo-fade {
    0% {
        opacity: 0;
        -moz-transform: translateY(-1.4em);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}
@keyframes silo-fade {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-1.4em);
        -moz-transform: translateY(-1.4em);
        -ms-transform: translateY(-1.4em);
        -o-transform: translateY(-1.4em);
        transform: translateY(-1.4em);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes silo-help {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes silo-help {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes silo-help {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}


/* Label styles */
#commentform label {
    padding: 5px 0;
    font-size: 14px;
    font-size: 0.75rem;
    display: none;
    position: absolute;
    top: 15px;
    left: 26px;
    color: #8BC34A;
    -webkit-animation: silo-fade 0.2s;
    -moz-animation: silo-fade 0.2s;
    animation: silo-fade 0.2s;
    max-width: 80%;
}
#commentform input:focus + label,
#commentform input[required]:valid + label,
#commentform input:not(:placeholder-shown) + label,
#commentform textarea:focus + label,
#commentform textarea:valid + label,
#commentform textarea:not(:placeholder-shown) + label {
    display: block;
}
#commentform input:invalid,
#commentform textarea:invalid {
    border: 1px solid #b92326;
    background-color: rgba(185, 35, 38, 0.25);
}
#commentform input:focus:invalid,
#commentform textarea:focus:invalid {
    background-color: transparent;
    border: 1px solid #b92326;
}
#commentform input:invalid + label,
#commentform textarea:invalid + label {
    color: #b92326;
}
#commentform input:invalid + label,
#commentform textarea:invalid + label {
    color: #b92326;
}
#commentform input:focus:invalid + label:after,
#commentform textarea:focus:invalid + label:after {
    content: " (" attr(data-help) ")";
    animation: silo-help 100s;
}
#commentform input:invalid + label:after,
#commentform textarea:invalid + label:after {
    content: " (" attr(data-help) ")";
}
#commentform input:valid,
#commentform textarea:valid {
    border: 1px solid #8BC34A;
    background-color: rgba(139, 195, 74, 0.25);
}
#commentform input:focus:valid,
#commentform textarea:focus:valid {
    border: 1px solid #8BC34A;
    background-color: transparent;
}
#commentform input:valid + label,
#commentform textarea:valid + label {
    color: #8BC34A;
}
#commentform input:placeholder-shown,
#commentform textarea:placeholder-shown {
    background-color: transparent;
    border: 1px solid #d8d8d8;
}
#commentform input::placeholder,
#commentform textarea::placeholder {
    color: #d8d8d8;
}
#commentform input::-webkit-input-placeholder,
#commentform textarea::-webkit-input-placeholder {
    color: #d8d8d8;
}
#commentform input:-ms-input-placeholder,
#commentform textarea:-ms-input-placeholder {
    color: #d8d8d8;
}
#commentform input::-moz-placeholder,
#commentform textarea::-moz-placeholder {
    color: #d8d8d8;
}
#commentform input:focus::placeholder,
#commentform textarea:focus::placeholder {
    color: transparent;
}
#commentform input:focus::-webkit-input-placeholder,
#commentform textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
#commentform input:focus:-ms-input-placeholder,
#commentform textarea:focus:-ms-input-placeholder {
    color: transparent;
}
#commentform input:focus::-moz-placeholder,
#commentform textarea:focus::-moz-placeholder {
    color: transparent;
}
#commentform input[type="submit"] {
    background-color: transparent;
    border: 1px solid #8BC34A;
    color: #8BC34A;
    padding: 5px 15px;
    font-size: 16px;
    font-size: 1rem;
    margin: 15px auto;
    vertical-align:middle;
    display: block;
    cursor: pointer;
}
#commentform input[type="submit"]:hover {
    background-color: rgba(139, 195, 74, 0.25);
    border: 1px solid #8BC34A;
} 