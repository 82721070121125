body {
    font-family: 'Times New Roman', Times, serif;
}

.Top-header {
    text-align: center;
}

.Top-header h1 {
    padding: 40px;
    font-size: 40px;
    color: rgb(158, 28, 28);
    font-weight: bolder;
}

.Top-header p {
    margin-left: 15px;
    margin-right: 15px;
    letter-spacing: 1px;
    font-size: 17px;
    text-align: left;
}


.middle-header {

    margin-top: 50px;
    background-color: rgb(224, 226, 227);
    height: 250px;
    display: flex;
    flex-direction: row;

}

.middle-header div {
    width: 80%;
    padding-top: 40px;
}

.left-type {
    float: left;
    padding-top: 10px;
    padding-left: 20px;
}

.left-type button {
    background-color: rgb(188, 14, 23);
    margin-top: 30px;
    padding: 10px;
    font-size: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    width: 80%;
}

.left-type button:hover {
    border-radius: 7px;
    box-shadow:inset;
    background-color: rgb(173, 14, 14);
    cursor: pointer;
}

.right-type {
    float: right;
    width: 45%;
    text-align: center;
}

.right-type h1 {
    padding-top: 20px;
    padding-bottom: 10px;
}

.right-type p {
    padding-top:3px ;
}


.inside-ethiopia {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    
}

.inside-ethiopia h1 {
    padding-top: 20px;
    padding-bottom: 30px;
}

.transactions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.transactions div {
    width: 14%;
    height: 90%;
}

.transactions > div > img {
    width: 80%;
    height: 100%;
}


.areas-giving {
    text-align: center;
    color: white;
    background-image: linear-gradient(to right, rgb(72, 0, 255) , rgb(198, 14, 41));

}

.areas-giving h1 {
    margin-bottom: 30px;
    padding-top: 20px;
}
.services {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    width: 97%;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 30px;
}


.icons {
    height: 50px;
    width: 30px;
    color: white;
}

.services div {
    width: 15%;
}

.services h1 {
    padding: 10px;
}

@media (max-width: 600px) {
    .Top-header {
        width: 95%;
        height: auto;
        margin-bottom: 10px;
    }

    .Top-header h1 {
        text-align: center;
    }

    .Top-header p {
        text-align: center;
        font-size: 19px;
    }

    .middle-header {
         display: flex;
        flex-direction: column;
        height: auto; 
        width: 100%;
        align-items: center; 
    }

    .left-type,
    .right-type {
        width:100px; /* Set width to 80% for small devices */
        height: auto;
        padding-bottom: 20px;
        
    }
    .areas-giving {
        text-align: center;
        color: white;
        background-image: linear-gradient(to right, rgb(72, 0, 255), rgb(198, 14, 41));
    }

    .transactions {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* flex-wrap: wrap; Ensure items wrap to next line */
        justify-content: center; /* Center items horizontally */
    }

    .transactions div {
        width: 75%; /* Adjust width for smaller screens */
        margin: 5px; /* Add some space between items */
    }

    .transactions > div > img {
        width: 100%; /* Make images fill the container */
    }

    .services {
        flex-wrap: wrap; /* Ensure items wrap to next line */
        justify-content: center; /* Center items horizontally */
        padding-bottom: 10px; /* Adjust spacing */
    }

    .services div {
        width: 90%; /* Adjust width for smaller screens */
        margin: 5px; /* Add some space between items */
        padding-top: 30px;
    }

    .services h1 {
        font-size: 18px; /* Adjust font size for smaller screens */
        font-weight: bolder;
    }
}

@media (max-width: 628px) {
    .Top-header {
        width: 95%;
        margin-bottom: 10px;
    }

    .Top-header h1 {
        text-align: center;
    }

    .Top-header p {
        text-align: center;
        font-size: 19px;
    }

    .middle-header {
        display: flex;
        flex-direction: column;
        height: auto; /* Let height be determined by content */
        width: auto;
        align-items: center;
    }

    .left-type,
    .right-type {
        width: 100%; /* Set width to 100% for medium devices */
        float: none; /* Remove float for better responsiveness */
        margin: 0 auto; /* Center the elements */
    }

    .areas-giving {
        text-align: center;
        color: white;
        background-image: linear-gradient(to right, rgb(72, 0, 255), rgb(198, 14, 41));
    }

    .transactions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Center items horizontally */
    }

    .transactions div {
        width: 75%; /* Adjust width for smaller screens */
        margin: 5px; /* Add some space between items */
    }

    .transactions > div > img {
        width: 100%; /* Make images fill the container */
    }

    .services {
        flex-wrap: wrap; /* Ensure items wrap to next line */
        justify-content: center; /* Center items horizontally */
        padding-bottom: 10px; /* Adjust spacing */
    }

    .services div {
        width: 90%; /* Adjust width for smaller screens */
        margin: 5px; /* Add some space between items */
        padding-top: 30px;
    }

    .services h1 {
        font-size: 18px; /* Adjust font size for smaller screens */
        font-weight: bolder;
    }
}
