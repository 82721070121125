/* BlogDetails.css */

body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--white);
  --accent: var(--black);
  background-color: var(--background);
  color: var(--accent);
}

* {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h2 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
}

.blog-details-container {
  max-width: 800px;
  padding: 100px 20px; /* Adjusted padding for better responsiveness */
  text-align: justify; /* Center content and justify text */
  margin: auto;
}

.blog-image img {
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px;
  padding: 40px 0; /* Adjusted padding for better responsiveness */
}

.blog-details-content {
  font-size: 1rem;
  line-height: 1.6;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}

.blog-paragraph {
  margin-bottom: 15px;
}

.blog-author {
  margin-top: 20px;
  font-style: italic;
}
